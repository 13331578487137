exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-offer-tsx": () => import("./../../../src/pages/offer.tsx" /* webpackChunkName: "component---src-pages-offer-tsx" */),
  "component---src-pages-pl-index-tsx": () => import("./../../../src/pages/pl/index.tsx" /* webpackChunkName: "component---src-pages-pl-index-tsx" */),
  "component---src-pages-pl-kontakt-tsx": () => import("./../../../src/pages/pl/kontakt.tsx" /* webpackChunkName: "component---src-pages-pl-kontakt-tsx" */),
  "component---src-pages-pl-oferta-tsx": () => import("./../../../src/pages/pl/oferta.tsx" /* webpackChunkName: "component---src-pages-pl-oferta-tsx" */),
  "component---src-pages-pl-realizacje-tsx": () => import("./../../../src/pages/pl/realizacje.tsx" /* webpackChunkName: "component---src-pages-pl-realizacje-tsx" */),
  "component---src-pages-realisations-tsx": () => import("./../../../src/pages/realisations.tsx" /* webpackChunkName: "component---src-pages-realisations-tsx" */)
}

